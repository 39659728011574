<template>
  <CRow>
    <CCol :sm="12">
        <div class="row">
          <div class="col-6">
            <h5>
              <CIcon size="lg" name="cil-group"/>
              BENUTZERVERWALTUNG
            </h5>
          </div>
          <div class="col-6">
            <CButton class="me-1 float-end" color="success" size="sm" v-on:click="newEntry">
              <CIcon name="cil-library-add"/> Neuer Benutzer
            </CButton>
          </div>
        </div>
            <div class="row mt-4">
              <CNav variant="tabs" role="tablist">
                <CNavItem>
                  <CNavLink
                    href="javascript:void(0);"
                    :active="tabPaneActiveKey === 1"
                    @click="() => {tabPaneActiveKey = 1}"
                  >
                    <CIcon name="cil-thumb-up"/>
                    <span>Aktive</span>
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href="javascript:void(0);"
                    :active="tabPaneActiveKey === 2"
                    @click="() => {tabPaneActiveKey = 2}"
                  >
                    <CIcon name="cil-thumb-down"/>
                    <span>Archiv</span>
                  </CNavLink>
                </CNavItem>
              </CNav>

              <CTabContent>
                <CTabPane role="tabpanel" aria-labelledby="home-tab" :visible="tabPaneActiveKey === 1">
                  <Table :benutzer="online" v-model="reload"/>
                </CTabPane>
                <CTabPane role="tabpanel" aria-labelledby="profile-tab" :visible="tabPaneActiveKey === 2">
                  <Table :benutzer="deleted" v-model="reload"/>
                </CTabPane>
              </CTabContent>
            </div>

    </CCol>
  </CRow>
</template>

<script>
import Table from '@/views/Benutzer/Table'

export default {
  name: 'app-user-index',
  components: {
    Table
  },
  data () {
    return {
      online: [],
      deleted: [],
      reload: '',
      tabPaneActiveKey: 1
    }
  },
  mounted () {
    this.loadBenutzer()
  },
  watch: {
    reload: function () {
      if (this.reload) {
        this.loadBenutzer()
        this.reload = ''
      }
    }
  },
  methods: {
    newEntry () {
      this.$router.push({ path: '/benutzer/neu' })
    },
    loadBenutzer () {
      this.axios.get('/user/get')
        .then((response) => {
          this.online = response.data
        })
      this.axios.get('/user/get/deleted')
        .then((response) => {
          this.deleted = response.data
        })
    }
  }
}
</script>
