<template>
  <CModal :visible="modelValue" :keyboard="false">
    <CModalHeader>
      <CModalTitle>{{title}}</CModalTitle>
    </CModalHeader>
    <CModalFooter>
      <CButton color="primary" @click="cancel">Nein</CButton>
      <CButton color="success" @click="ok">Ja</CButton>
    </CModalFooter>
  </CModal>
</template>

<script>

export default {
  name: 'Confirm',
  props: {
    modelValue: {
      default: false,
      type: Boolean
    },
    title: {
      default: 'Daten wirklich löschen?',
      type: String
    }
  },
  emits: [
    'update:modelValue',
    'confirmEvent'
  ],
  methods: {
    cancel () {
      this.$emit('update:modelValue', false)
    },
    ok () {
      this.$emit('update:modelValue', false)
      this.$emit('confirmEvent')
    }
  }
}
</script>
