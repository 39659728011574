<template>
  <CSmartTable
    v-if="benutzer && benutzer.length > 0"
    :items="benutzer"
    :columns="columns"
    :tableProps="{
      striped: true,
      hover: true,
    }"
    column-filter
    items-per-page-select
    items-per-page-label="Einträge pro Seite"
    :items-per-page="20"
    column-sorter
    :sorter-value="{ column: 'name', state: 'asc' }"
    pagination
  >
    <template #_id="{item}">
      <td>
        {{ item._id +1 }})
      </td>
    </template>
    <template #roles="{item}">
      <td v-if="item.roles">
        <span v-for="role in item.roles" v-bind:key="role">
          <span v-if="role == 'ROLE_ADMIN'">Administrator</span>
          <span v-if="role == 'ROLE_SUPERUSER'">Superuser</span>
          <span v-if="role == 'ROLE_EDITOR'">Editor</span>
          <span v-if="role == 'ROLE_USER'">Benutzer</span>
        </span>
      </td>
      <td v-else>
        -
      </td>
    </template>
    <template #lastLoginAt="{item}">
      <td v-if="item.lastLoginAt">
        {{momentDatum(item.lastLoginAt)}}
        um
        {{momentUhrzeit(item.lastLoginAt)}} Uhr
      </td>
      <td v-else>
        -
      </td>
    </template>
    <template #actionbar="{item}">
      <td nowrap="" width="1%">
      <CButtonGroup>
        <CButton
          color="danger"
          size="sm"
          class="ml-2"
          v-if="item.exempt"
          v-c-tooltip="{content: 'Freistellung aufheben', placement:'top'}"
          v-on:click="confirmExemptRedo(item.mitarbeiterid)"
        >
          <CIcon name="cil-https"/>
        </CButton>
        <CButton
          color="success"
          size="sm"
          class="ml-2"
          v-if="!item.exempt"
          v-c-tooltip="{content: 'Freistellen', placement:'top'}"
          v-on:click="confirmExempt(item.mitarbeiterid)"
        >
          <CIcon name="cil-https"/>
        </CButton>
      </CButtonGroup>
        &nbsp;
        &nbsp;
        <CButtonGroup role="group" aria-label="Basic example">
          <CButton
            color="warning"
            size="sm"
            v-c-tooltip="{content: 'Editieren', placement:'top'}"
            v-on:click="editBenutzer(item.mitarbeiterid)"
          >
            <CIcon name="cil-highlighter"/>
          </CButton>
          <CButton
            color="warning"
            square
            size="sm"
            class="ml-2"
            v-c-tooltip.hover.click="{content: 'Passwort ändern', placement:'top'}"
            v-on:click="passwordChange(item.mitarbeiterid)"
          >
            <CIcon name="cil-https"/>
          </CButton>
          <CButton
            color="danger"
            size="sm"
            class="ml-2"
            v-if="!item.deletedAt"
            v-c-tooltip="{content: 'Löschen', placement:'top'}"
            v-on:click="confirmDelete(item.mitarbeiterid)"
          >
            <CIcon name="cil-trash"/>
          </CButton>
          <CButton
            color="success"
            size="sm"
            class="ml-2"
            v-if="item.deletedAt"
            v-c-tooltip="{content: 'Wiederherstellen', placement:'top'}"
            v-on:click="confirmUndelete(item.mitarbeiterid)"
          >
            <CIcon name="cil-lock-unlocked"/>
          </CButton>
        </CButtonGroup>
      </td>
    </template>
  </CSmartTable>
  <no-data v-else />
  <Confirm v-model="askDelete" title="Benutzer Löschen?" @confirmEvent="deleteBenutzer"/>
  <Confirm v-model="askUndelete" title="Benutzer Wiederherstellen?" @confirmEvent="undeleteBenutzer"/>
</template>

<script>
import NoData from '@/components/NoData'
import Confirm from '@/components/Confirm'
import { useToast } from 'vue-toastification'
const moment = require('moment')
require('moment/locale/de')

export default {
  name: 'app-user-tabelle',
  components: {
    Confirm,
    NoData
  },
  props: {
    benutzer: Array,
    modelValue: String
  },
  setup () {
    return {
      toast: useToast()
    }
  },
  data () {
    return {
      askDelete: false,
      askUndelete: false,
      activeMitarbeiter: undefined,
      columns: [
        {
          key: '_id',
          label: '#',
          filter: false,
          sorter: false,
          _width: ' 5%'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'email',
          label: 'E-Mail'
        },
        {
          key: 'roles',
          label: 'Level'
        },
        {
          key: 'boxNo',
          label: 'Kühlbox-Nr.'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  methods: {
    momentDatum: function (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    momentUhrzeit: function (date) {
      return moment(date).format('HH:mm:ss')
    },
    passwordChange (mitarbeiterid) {
      this.$router.push({ path: `/benutzer/passwort/${mitarbeiterid}` })
    },
    editBenutzer (mitarbeiterid) {
      this.$router.push({ path: `/benutzer/editieren/${mitarbeiterid}` })
    },
    confirmDelete: function (mitarbeiterid) {
      this.askDelete = true
      this.activeMitarbeiter = mitarbeiterid
    },
    confirmUndelete: function (mitarbeiterid) {
      this.askUndelete = true
      this.activeMitarbeiter = mitarbeiterid
    },
    deleteBenutzer: function () {
      const self = this

      this.axios.delete('/user/delete/' + this.activeMitarbeiter)
        .then((response) => {
          self.$emit('update:modelValue', self.activeMitarbeiter)
          self.toast.success('Der Benutzer wurde gelöscht.')
          self.activeMitarbeiter = undefined
        })
    },
    undeleteBenutzer: function () {
      const self = this

      this.axios.delete('/user/undelete/' + this.activeMitarbeiter)
        .then((response) => {
          self.$emit('update:modelValue', self.activeMitarbeiter)
          self.toast.success('Der Benutzer wurde wiederhergestellt.')
          self.activeMitarbeiter = undefined
        })
    }
  }
}
</script>
